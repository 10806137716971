import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function TopDonorsTable() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Extract and sort donor data
  const donationsData = entries
    .map((entry) => {
      const meta = entry.meta || {};
      const firstName = meta["First Name"]?.[0] || "Unknown";
      const lastName = meta["Last Name"]?.[0] || "Unknown";
      const donation = parseFloat(meta["avjwx2-value"] || "0");

      return {
        name: `${firstName} ${lastName}`,
        value: donation,
      };
    })
    .filter((donationData) => donationData.value > 0) // Filter out entries with no donations
    .sort((a, b) => b.value - a.value); // Sort by donation amount in descending order

  return (
    <div className="overflow-y-scroll no-scrollbar rounded-lg bg-white shadow">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Donation Amount
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {donationsData.map((donationData, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {donationData.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                ${donationData.value.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
