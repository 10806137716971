import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function TotalDonationRevenue() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Sum total donation revenue
  const totalDonationRevenue = entries.reduce((acc, entry) => {
    if (entry.meta["Paid?"] === "Yes" && entry.meta["avjwx2-value"]) {
      const value = parseFloat(entry.meta["avjwx2-value"]);
      if (!isNaN(value)) {
        return acc + value;
      }
    }
    return acc;
  }, 0);

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Donation Revenue
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        $
        {totalDonationRevenue.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </dd>
    </div>
  );
}
