import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function TotalRegistrationRevenue() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate total registration revenue
  const totalRegistrationRevenue = entries.reduce((total, entry) => {
    // Ensure meta exists
    const meta = entry.meta || {};
    const registrationFees = Array.isArray(meta["Registration"])
      ? meta["Registration"].filter((fee) => !isNaN(parseFloat(fee)))
      : [];
    const numberOfRegistrants = parseInt(
      meta["Number of Registrants"] || "0",
      10
    );

    // Convert registration fees to numbers and sum them up
    const rawRegistrationRevenue = registrationFees.reduce(
      (sum, fee) => sum + parseFloat(fee),
      0
    );

    // Apply 12% discount if number of registrants is 4 or more
    const entryRevenue =
      numberOfRegistrants >= 4
        ? rawRegistrationRevenue * 0.88
        : rawRegistrationRevenue;

    // Add to total revenue
    return total + entryRevenue;
  }, 0);

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Registration Revenue
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        $
        {totalRegistrationRevenue.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </dd>
    </div>
  );
}
