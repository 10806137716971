import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { generateColorFromName } from "../../../../utils/utils";

export default function RevenueDistributionGraph() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  type RevenueKeys = "registrationFees" | "bazaarTable" | "donation";

  // Calculate revenue distribution
  const revenueDistribution = entries.reduce(
    (totals, entry) => {
      const meta = entry.meta || {};

      // Calculate registration fees
      const registrationFees = Array.isArray(meta["Registration"])
        ? meta["Registration"]
            .filter((fee) => !isNaN(parseFloat(fee)))
            .reduce((sum, fee) => sum + parseFloat(fee), 0)
        : 0;

      const numberOfRegistrants = parseInt(
        meta["Number of Registrants"] || "0",
        10
      );

      const discountedRegistrationRevenue =
        numberOfRegistrants >= 4 ? registrationFees * 0.88 : registrationFees;

      // Calculate bazaar table count
      const bazaarTableRevenue = parseInt(
        meta["How many tables do you need? ($25/per table)"] || "0",
        10
      );

      // Calculate donation revenue
      const donationRevenue = parseFloat(meta["avjwx2-value"] || "0");

      // Update totals
      totals.registrationFees += discountedRegistrationRevenue;
      totals.bazaarTable += bazaarTableRevenue * 25;
      totals.donation += donationRevenue;

      return totals;
    },
    { registrationFees: 0, bazaarTable: 0, donation: 0 }
  );

  // Map revenue distribution keys to user-friendly names
  const friendlyNamesMap: Record<RevenueKeys, string> = {
    registrationFees: "Registration Fees",
    bazaarTable: "Bazaar Table Revenue",
    donation: "Donations",
  };

  const revenueData = Object.entries(revenueDistribution).map(
    ([key, value]) => ({
      name: friendlyNamesMap[key as RevenueKeys],
      value,
    })
  );

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-2">
      <h1 className="text-xl text-primary text-center">Revenue Distribution</h1>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={revenueData}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#1b3356"
            label={({ name, value }) => `${name}: $${value.toFixed(2)}`}
          >
            {revenueData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={generateColorFromName(entry.name)}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
