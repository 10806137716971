import { Route, Routes as Rts } from "react-router-dom";
import SignedInRoute from "./auth/SignedInRoute";
import SignInPage from "../pages/auth/SignInPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import ForbiddenPage from "../pages/error/403ForbiddenPage";
import NotFoundPage from "../pages/error/404NotFoundPage";
import DashboardPage from "../pages/internal/DashboardPage/DashboardPage";
import UserSettingsPage from "../pages/internal/UserSettingsPage/UserSettingsPage";
import ReportsPage from "../pages/internal/ReportsPage/ReportsPage";
import AllRegistrationsPage from "../pages/internal/AllRegistrationsPage/AllRegistrationsPage";
import RegistrantPage from "../pages/internal/RegistrantPage/RegistrantPage";
import QRCodeScanPage from "../pages/internal/QRCodeScanPage/QRCodeScanPage";
import CheckInPage from "../pages/internal/CheckInPage/CheckInPage";
import BazaarTableReportPage from "../pages/internal/ReportsPage/BazaarTableReportPage/BazaarTableReportPage";
import { Outlet } from "react-router-dom";
import GeneralReportPage from "../pages/internal/ReportsPage/GeneralReportPage/GeneralReportPage";
import FinancialReportPage from "../pages/internal/ReportsPage/FinancialReportPage/FinancialReportPage";

export default function Routes() {
  return (
    <Rts>
      {/* Protected Routes */}
      <Route
        element={
          <SignedInRoute>
            <Outlet />
          </SignedInRoute>
        }
      >
        <Route index path="/" element={<DashboardPage />} />
        <Route path="/all-registrations" element={<AllRegistrationsPage />} />
        <Route path="/registrant/:registrantKey" element={<RegistrantPage />} />
        <Route path="/scan" element={<QRCodeScanPage />} />
        <Route path="/check-in/:registrantKey" element={<CheckInPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route index path="/reports/general" element={<GeneralReportPage />} />
        <Route
          path="/reports/bazaar-table"
          element={<BazaarTableReportPage />}
        />
        <Route path="/reports/financial" element={<FinancialReportPage />} />
        <Route path="/settings" element={<UserSettingsPage />} />
      </Route>

      {/* Public Routes */}
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />

      {/* Misc Routes */}
      <Route path="/forbidden" element={<ForbiddenPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Rts>
  );
}
