import axios, { AxiosInstance } from "axios";
import { formFields } from "./form_fields";
import { ApiResponse, FormEntry, Meta, UpdatePayload } from "../types/types";

// Basic Auth credentials
const username = "GJRE-7YLX-YF4L-6UQZ";
const password = "x";

// Axios instance for the API
const api: AxiosInstance = axios.create({
  baseURL: "https://conference.msapsg.org/wp-json/frm/v2",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + btoa(`${username}:${password}`),
  },
});

/**
 * Function to fetch and map form entries.
 *
 * @returns A promise that resolves with all paid entries.
 */
export const getFormEntries = async (): Promise<any> => {
  try {
    const perPage = 500;

    const response = await api.get<ApiResponse>(`/forms/13/entries`, {
      params: {
        page_size: perPage,
      },
    });

    const data = response.data;

    // Map field keys to field names in each entry
    const mappedData = Object.keys(data).map((entryKey) => {
      const entry = data[entryKey];

      // Transform meta fields
      const mappedMeta = Object.keys(entry.meta).reduce((acc, key) => {
        // Look up human-readable name or fall back to the original key
        const fieldName = formFields[key]?.name || key;
        acc[fieldName] = entry.meta[key];
        return acc;
      }, {} as Meta);

      return {
        ...entry,
        meta: mappedMeta,
      };
    });

    // Filter for only entries where "Paid?" is "Yes"
    const paidEntries = mappedData.filter(
      (entry) => entry.meta["Paid?"] === "Yes"
    );

    return paidEntries;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("API Error:", error.message);
    } else {
      console.error("Unexpected Error:", error);
    }
    throw error;
  }
};

/**
 * Sends a PATCH request to check in multiple registrants.
 *
 * @param ids - Array of entry IDs to update.
 * @param parentEntryId - The parent entry ID to include in the request body.
 * @returns A promise that resolves when all requests are complete.
 */
export async function checkInRegistrants(
  ids: string[],
  parentEntryId: string
): Promise<void> {
  const requests = ids.map((id) => {
    const payload: UpdatePayload = {
      "426": "true",
      parent_item_id: parentEntryId,
      form_id: 14,
    };

    return api.patch(`/entries/${id}`, payload);
  });

  try {
    await Promise.all(requests);
  } catch (error) {
    console.error("Error checking in registrants:", error);
  }
}
