import InternalPage from "../../../../components/layout/internal/InternalPage";
import AverageTablesPerRegistration from "./AverageTablesPerRegistration";
import BazaarInfoTable from "./BazaarInfoTable";
import TotalTableCount from "./TotalTableCount";
import TotalTableRevenue from "../FinancialReportPage/TotalTableRevenue";

export default function BazaarTableReportPage() {
  return (
    <InternalPage>
      <div>
        <dl className="grid grid-cols-2 sm:grid-cols-4 gap-1 sm:gap-x-5 sm:gap-y-5">
          <TotalTableCount />
          <TotalTableRevenue />
          <AverageTablesPerRegistration />
        </dl>
        <dl className="mt-5 grid grid-cols-1 sm:grid-cols-3 gap-x-0 sm:gap-x-5 gap-y-5 h-[600px]">
          <BazaarInfoTable />
        </dl>
      </div>
    </InternalPage>
  );
}
