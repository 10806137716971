import InternalPage from "../../../components/layout/internal/InternalPage";
import AgeDistributionGraph from "./AgeDistributionGraph";
import AgeDistributionTable from "./AgeDistributionTable";
import CheckedInCount from "./CheckInCount";
import EthnicityDistributionGraph from "./EthnicityDistributionGraph";
import EthnicityDistributionTable from "./EthnicityDistributionTable";
import GenderDistributionGraph from "./GenderDistributionGraph";
import MoneyTotalCount from "../ReportsPage/FinancialReportPage/MoneyTotalCount";
import ProgramCountTable from "./ProgramCountTable";
import SubmissionsCount from "./SubmissionsCount";
import TotalRegistrantCount from "./TotalRegistrantsCount";

export default function DashboardPage() {
  return (
    <InternalPage>
      <div>
        <dl className="grid grid-cols-2 sm:grid-cols-4 gap-1 sm:gap-x-5 sm:gap-y-5">
          <CheckedInCount />
          <TotalRegistrantCount />
          <SubmissionsCount />
          <MoneyTotalCount />
        </dl>
        <dl className="mt-5 grid grid-cols-1 sm:grid-cols-3 gap-x-0 sm:gap-x-5 gap-y-5 h-[700px] sm:h-[500px]">
          <GenderDistributionGraph />
          <ProgramCountTable />
        </dl>
        <dl className="mt-5 grid grid-cols-1 sm:grid-cols-3 gap-x-0 sm:gap-x-5 gap-y-5 h-[700px] sm:h-[500px]">
          <EthnicityDistributionGraph />
          <EthnicityDistributionTable />
        </dl>
        <dl className="mt-5 grid grid-cols-1 sm:grid-cols-3 gap-x-0 sm:gap-x-5 gap-y-5 h-[700px] sm:h-[500px]">
          <AgeDistributionGraph />
          <AgeDistributionTable />
        </dl>
      </div>
    </InternalPage>
  );
}
