import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function TotalTableRevenue() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate the total revenue generated from table bookings
  const totalTableRevenue = entries.reduce((total, entry) => {
    const tableCount = parseInt(
      entry.meta["How many tables do you need? ($25/per table)"] || "0",
      10
    );
    return total + (isNaN(tableCount) ? 0 : tableCount * 25);
  }, 0);

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Table Revenue
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        $
        {totalTableRevenue.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </dd>
    </div>
  );
}
