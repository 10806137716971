import {
  BuildingStorefrontIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import InternalPage from "../../../components/layout/internal/InternalPage";
import ReportCard from "./ReportCard";

const reports = [
  { name: "General", href: "general", icon: ChartPieIcon, disabled: true },
  {
    name: "Bazaar Table",
    href: "bazaar-table",
    icon: BuildingStorefrontIcon,
    disabled: false,
  },
  { name: "Demographic", href: "demographic", icon: UsersIcon, disabled: true },
  {
    name: "Financial",
    href: "financial",
    icon: CurrencyDollarIcon,
    disabled: false,
  },
];

export default function ReportsPage() {
  return (
    <InternalPage>
      <div>
        <dl className="grid grid-cols-2 sm:grid-cols-4 gap-1 sm:gap-x-5 sm:gap-y-5">
          {reports.map((report, idx) => (
            <ReportCard key={idx} report={report} />
          ))}
        </dl>
      </div>
    </InternalPage>
  );
}
