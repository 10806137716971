import { Link } from "react-router-dom";

export default function ReportCard({
  report,
}: {
  report: {
    name: string;
    href: string;
    icon: React.ForwardRefExoticComponent<
      Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
        title?: string | undefined;
        titleId?: string | undefined;
      } & React.RefAttributes<SVGSVGElement>
    >;
    disabled: boolean;
  };
}) {
  if (report.disabled)
    return (
      <div className="flex flex-col text-gray-400 items-center rounded-md shadow-md bg-white p-8 sm:p-10">
        <span className="relative rotate-45 -right-8 top-0 items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
          Coming Soon
        </span>
        <report.icon
          aria-hidden="true"
          className="group-hover:text-white size-14 shrink-0"
        />
        <h2>{report.name}</h2>
      </div>
    );
  else
    return (
      <Link
        to={report.href}
        className="flex flex-col items-center rounded-md shadow-md bg-white hover:bg-gray-400/5 p-8 sm:p-10 transition-colors"
      >
        <report.icon
          aria-hidden="true"
          className="text-gray-900 group-hover:text-white size-14 shrink-0"
        />
        <h2>{report.name}</h2>
      </Link>
    );
}
