import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function TotalTableCount() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate the number of tables needed
  const totalTables = entries.filter(
    (entry) => entry.meta["Will you be needing a table for bazaar?"] === "Yes"
  ).length;

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Tables Needed
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {totalTables}
      </dd>
    </div>
  );
}
