import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function BazaarInfoTable() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Filter entries where "Will you be needing a table for bazaar?" is "Yes"
  const bazaarRequests = entries
    .filter(
      (entry) => entry.meta["Will you be needing a table for bazaar?"] === "Yes"
    )
    .map((entry) => ({
      name: `${entry.meta["First Name"]?.[0] || "N/A"} ${
        entry.meta["Last Name"]?.[0] || "N/A"
      }`,
      email: entry.meta["Email"]?.[0] || "N/A",
      phoneNumber: entry.meta["Phone"] || "N/A",
      description:
        entry.meta["Please tell us more about the items you wish to sell"] ||
        "N/A",
      tables: parseInt(
        entry.meta["How many tables do you need? ($25/per table)"] || "0",
        10
      ),
    }));

  return (
    <div className="overflow-y-scroll no-scrollbar rounded-lg bg-white shadow col-span-4">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Tables Needed
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Phone Number
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Description
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {bazaarRequests.map((entry, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {entry.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {entry.tables}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <a href={`mailto:${entry.email}`} className="hover:underline">
                  {entry.email}
                </a>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <a
                  href={`tel:${entry.phoneNumber}`}
                  className="hover:underline"
                >
                  {entry.phoneNumber}
                </a>
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-normal break-words">
                {entry.description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
