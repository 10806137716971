import InternalPage from "../../../../components/layout/internal/InternalPage";
import TotalDonationRevenue from "./TotalDonationRevenue";
import TotalTableRevenue from "./TotalTableRevenue";
import MoneyTotalCount from "./MoneyTotalCount";
import TotalRegistrationRevenue from "./TotalRegistrationRevenue";
import RevenueDistributionGraph from "./RevenueDistributionGraph";
import TopDonorsTable from "./TopDonorsTable";

export default function FinancialReportPage() {
  return (
    <InternalPage>
      <div>
        <dl className="grid grid-cols-2 sm:grid-cols-4 gap-1 sm:gap-x-5 sm:gap-y-5">
          <TotalRegistrationRevenue />
          <TotalTableRevenue />
          <TotalDonationRevenue />
          <MoneyTotalCount />
        </dl>
        <dl className="mt-5 grid grid-cols-1 sm:grid-cols-3 gap-x-0 sm:gap-x-5 gap-y-5 h-[700px] sm:h-[500px]">
          <RevenueDistributionGraph />
          <TopDonorsTable />
        </dl>
      </div>
    </InternalPage>
  );
}
