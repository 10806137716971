import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function PercentageTableRegistrations() {
  const entries = useSelector((state: RootState) => state.formEntries.entries);

  // Calculate the percentage of registrations that need a table
  const totalRegistrations = entries.length;
  const registrationsNeedingTable = entries.filter(
    (entry) => entry.meta["Will you be needing a table for bazaar?"] === "Yes"
  ).length;

  const percentage =
    totalRegistrations > 0
      ? (registrationsNeedingTable / totalRegistrations) * 100
      : 0;

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        Average Registrants Needing Table
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {percentage.toFixed(2)}%
      </dd>
    </div>
  );
}
